<template>
  <div id="PreviousTransactions">
    <div class="panel">
      <prevTransactions :results="results" />
    </div>
  </div>
</template>
<script>
import prevTransactions from "@/components/nonPageComponents/previousTransactionsTable";
export default {
  data() {
    return {
      limit: 50,
      results: null
    };
  },
  components: {
    prevTransactions
  },
  async mounted() {
    this.results = await this.$api.prevTransactions(this.limit);
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/css/global-variables.scss";

::v-deep .b-table-sticky-header {
  max-height: calc(100vh - #{$nav-height} - #{$panel-margin}* 2 - 46px);
}

table {
  width: 100%;
}

tbody tr:hover {
  color: red;
}

#prev-transactions-header {
  display: flex;
  justify-content: flex-end;
}
</style>
